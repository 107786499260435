export default class Validation {
  constructor () {
    this.maxSize = 5242880
    this.extensiones = ['jpg', 'jpeg', 'gif']
  }

  validarImagen (fileImg) {
    let pasaValidacion = true

    if (!this.validarImagenSize(fileImg['size']) ||
        !this.validarImagenTipo(fileImg['type']) ||
        !this.validarImagenExtension(fileImg['name'])) {
      pasaValidacion = false
    }
    return pasaValidacion
  }

  validarImagenTipo (tipo) {
    // return tipo.split('/')[0] === 'image'
    let partesTipo = tipo.split('/')
    return partesTipo[0] === 'image'
  }

  validarImagenSize (size) {
    return size <= this.maxSize
  }

  validarImagenExtension (nombre) {
    let partesNombre = nombre.split('.')
    let ext = partesNombre[(partesNombre.length - 1)]
    return this.extensiones.includes(ext.toLowerCase())
  }

  static mayorQue (value1, value2, canBeEquals) {
    canBeEquals = canBeEquals || true
    value1 = parseFloat(value1)
    value2 = parseFloat(value2)
    if (canBeEquals) {
      return value1 >= value2
    } else {
      return value1 > value2
    }
  }

  static validarCargaPresupuesto (presupuesto) {
    let resultado = 1
    if (!Validation.mayorQue(presupuesto.cantidadJornadas, presupuesto.jornadasCotizar, true)) {
      resultado = 2
    }
    if (!presupuesto.presupuestoProductos.length > 0) {
      resultado = 3
    }
    if (presupuesto.fechaEvento === '') {
      resultado = 4
    }

    return resultado
  }

  static pasaValidacion (resultado) {
    return resultado === 1
  }

  static valueCompleted (value) {
    return (value !== '' && value !== null && value !== undefined)
  }
}

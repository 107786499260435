export default class TimeHelper {
  constructor (time) {
    this.time = time
  }

  static isEmpty (time) {
    return time === undefined || time === null || time === '' || time === '00:00:00'
  }

  static validValue (value) {
    return (TimeHelper.isEmpty(value)) ? '' : value
  }
}

<template>
  <div class="fullheight">
    <spinner v-show="loading" texto="Cargando, por favor aguarde..."></spinner>
    <div class="content-header flex-row flex-row-between" v-if="!loading && evento !== null">
      <h1>Formulario datos de entrega - Evento n° {{ idEvento }}</h1>

      <div class="content-header-buttons flex-row">
        <button class="btn-primary animated" @click="sendForm()">Guardar</button>
      </div>
    </div>

    <div class="content-content scrolleable flex-column flex-column-left flex-column-top" v-if="!loading && evento !== null">
      <form class="form-container fullwidth">
        <div class="flex-row flex-row-left flex-row-nomargin">
          <p class="content-subtit">Datos de entrega</p>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <select v-model="datosEntrega.tipoEvento" id="evento-selected-tipo-evento" name="Tipo de evento" class="form-field form-field-nomargin"
            :class="{'field-complete': datosEntrega.tipoEvento !== ''}">
              <option value="">Elija tipo de evento</option>
              <option v-for="tipoEvento in tiposEventos" :key="tipoEvento.value" :value="tipoEvento.text">
                {{ tipoEvento.text }}
              </option>
            </select>
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
                   for="evento-selected-tipo-evento">Tipo de evento</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <datepicker
              v-model="evento.fechaEvento"
              disabled
              name="Fecha del evento"
              class="fullwidth"
              placeholder="Elija una fecha"
              type="text"
              :format="DateHelper.formatoFechaAmigable"
              :language="datepickerOptions.language"
              :class="fechaEventoClasses"
              :input-class="fechaEventoInputClasses"
            />
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active">Fecha de evento</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.horaEvento" id="evento-selected-hora-evento" name="Hora de evento" placeholder="Escriba un horario (hh:mm)"
                   class="form-field form-field-nomargin" :class="{'field-complete': !TimeHelper.isEmpty(datosEntrega.horaEvento)}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-hora-evento">Hora de evento</label>
          </div>
        </div>

        <div class="flex-row" v-if="showLugarFields">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <select v-model="datosEntrega.tipoLugar" id="evento-selected-tipo-lugar" name="Tipo de lugar" class="form-field form-field-nomargin"
            :class="{'field-complete': datosEntrega.tipoLugar !== ''}">
              <option value="">Elija tipo de lugar</option>
              <option v-for="tipoLugar in tiposLugares" :key="tipoLugar.value" :value="tipoLugar.text">
                {{ tipoLugar.text }}
              </option>
            </select>
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
                   for="evento-selected-tipo-lugar">Tipo de lugar</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.direccion" v-validate="'required'" id="evento-selected-direccion" name="Direccion"
                   class="form-field form-field-nomargin" placeholder="Escriba la dirección"
                   :class="{error: errors.has('Direccion'), 'field-complete': valueCompleted(datosEntrega.direccion)}"
                   type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-direccion">Dirección</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.localidad" id="evento-selected-localidad" name="Localidad"
                   class="form-field form-field-nomargin" type="text" placeholder="Escriba la localidad"
                   :class="{'field-complete': valueCompleted(datosEntrega.localidad)}">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-localidad">Localidad</label>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.comienzoEvento" id="evento-selected-comienzo-evento" name="Comienzo del evento" placeholder="Fecha y hora de comienzo del evento"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.comienzoEvento !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-comienzo-evento">Comienzo del evento (fecha y hora)</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.finalizacionEvento" id="evento-selected-finalizacion-evento" name="Finalizacion del evento" placeholder="Duración o finalizacion del evento"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.finalizacionEvento !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-finalizacion-evento">Duracion/finalizacion del evento</label>
          </div>
        </div>
      </form>
    </div>

    <div class="fullheight flex-row" v-if="!evento">
      <span class="text-no-event">
        <strong>Oops!</strong> Parece que algo salió mal. <br>
        No se ha encontrado un evento válido.
      </span>
    </div>
  </div>
</template>

<script>
import ajax from '@/ajax'
import { EventBus } from '@/event-bus.js'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/dist/locale'
import DateHelper from '@/DateHelper'
import TimeHelper from '@/TimeHelper'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import Validation from '@/Validation'
import ConstantsHelper from '@/ConstantsHelper'
import spinner from '@/components/spinner.vue'
const moment = extendMoment(Moment)

export default {
  components: {
    Datepicker,
    spinner
  },
  data () {
    return {
      DateHelper,
      TimeHelper,
      loading: false,
      evento: null,
      idEvento: '',
      fechaEvento: '',
      datosEntrega: {
        tipoEvento: '',
        horaEvento: '',
        tipoLugar: '',
        direccion: '',
        localidad: '',
        comienzoEvento: '',
        finalizacionEvento: ''
      },
      datepickerOptions: {
        language: es,
        disabledDates: {
          to: moment().subtract(1, 'd').toDate()
        }
      },
      tiposEventos: [
        {
          value: 'corporativo',
          text: 'CORPORATIVO'
        },
        {
          value: 'social-boda',
          text: 'SOCIAL BODA'
        },
        {
          value: 'social-ceremonia-civil',
          text: 'SOCIAL CEREMONIA CIVIL'
        },
        {
          value: 'social-cumpleanos',
          text: 'SOCIAL CUMPLEAÑOS'
        },
        {
          value: 'social-otro',
          text: 'SOCIAL OTRO'
        },
        {
          value: 'feria-expo',
          text: 'FERIA/EXPO'
        }
      ],
      tiposLugares: [
        {
          value: 'SITIO DE EVENTOS',
          text: 'SITIO DE EVENTOS'
        },
        {
          value: 'SUM BARRIO CERRADO',
          text: 'SUM BARRIO CERRADO'
        },
        {
          value: 'PROPIEDAD EN BARRIO CERRADO',
          text: 'PROPIEDAD EN BARRIO CERRADO'
        },
        {
          value: 'SUM EN EDIFICIOS',
          text: 'SUM EN EDIFICIOS'
        },
        {
          value: 'EDIFICIO O CASA PARTICULAR',
          text: 'EDIFICIO O CASA PARTICULAR'
        },
        {
          value: 'CAMPO / QUINTA PRIVADA',
          text: 'CAMPO / QUINTA PRIVADA'
        }
      ]
    }
  },
  computed: {
    fechaEventoClasses () {
      const classes = []
      if (!DateHelper.isEmpty(this.evento.fechaEvento)) {
        classes.push('field-complete-datepicker')
      }
      return classes
    },
    fechaEventoInputClasses () {
      const classes = []
      if (!DateHelper.isEmpty(this.evento.fechaEvento)) {
        classes.push('field-complete')
      }
      /* if (errors.has('Fecha del evento')) {
        classes.push('error')
      } */
      return classes
    },
    showLugarFields () {
      return this.evento.lugar.tipoLugar.id !== 2
    }
  },
  methods: {
    valueCompleted (value) {
      return Validation.valueCompleted(value)
    },
    getEventoById (id) {
      this.loading = true
      ajax('GET', ConstantsHelper.getURLs().v1 + 'eventos.php?id=' + id + '&all=true')
        .then((response) => {
          if (response.code === 1 && response.data.length > 0) {
            this.evento = response.data[0]
            let fechaFormatoBD = DateHelper.formatoCalendario(this.evento.fechaEvento)
            let dateParts = fechaFormatoBD.split('-')
            this.evento.fechaEvento = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
            this.setDatosEntregaFromEvento(this.evento)

            // const comienzo = this.evento?.datosEntrega?.

            EventBus.$emit('generateToast', {
              message: response.response,
              type: 'correct'
            })
          } else {
            EventBus.$emit('generateToast', {
              message: response.response,
              type: 'error'
            })
          }
          this.loading = false
        })
    },
    setDatosEntregaFromEvento (evento) {
      this.datosEntrega.tipoEvento = evento.datosEntrega.tipoEvento
      this.datosEntrega.horaEvento = evento.datosEntrega.horaEvento
      this.datosEntrega.direccion = evento.datosEntrega.direccion
      this.datosEntrega.localidad = evento.datosEntrega.localidad
      this.datosEntrega.tipoLugar = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Tipo de Lugar')
      this.datosEntrega.comienzoEvento = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Comienzo del evento')
      this.datosEntrega.finalizacionEvento = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Finalizacion del evento')
    },
    extractFromEventField (text, tag) {
      const regex = new RegExp(`${tag}: (.*?)(?= -|$)`, 'i')
      const resultado = regex.exec(text)
      return resultado ? resultado[1].trim() : null
    },
    sendForm () {
      this.loading = true
      ajax('PUT', ConstantsHelper.getURLs().v1 + 'formulario-datos-entrega.php', {
        datosEntrega: this.datosEntrega,
        id_evento: this.idEvento,
        action: 'form1'
      })
        .then((response) => {
          let type = 'error'
          if (response.status === 1) {
            type = 'correct'
          }
          EventBus.$emit('generateToast', {
            message: response.message,
            type: type
          })
        })
        .catch((error) => {
          EventBus.$emit('generateToast', {
            message: 'Surgio un error!',
            type: 'error'
          })
          console.log('Operacion fallida', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    if (this.$route.query.evento) {
      this.idEvento = this.$route.query.evento
      this.getEventoById(this.idEvento)
    }
  }
}
</script>

<style scoped>
.fullheight {
  height: 100vh;
}

.content-header {
  margin-top: 25px;
}
.content-content {
  min-height: calc(100vh - 40px - 55px);
}

.text-no-event {
  font-size: 2rem;
}
</style>
